import React, { useEffect, useState } from 'react';
import SummaryTable from '../components/SummaryTable';
import BasicWordCloudPlot from '../components/BasicWordCloudPlot';
import BasicPiePlot from '../components/BasicPiePlot';
import SingleQuestionNav from '../components/SingleQuestionNav';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import api from '../utils/axios';

const boxes = [
  { name: 'Survey Views', value: 12 },
  { name: 'Survey Responses', value: 13 },
  { name: 'Amount Deducted', value: 1400 },
  { name: 'Amount Remaining', value: 1500 },
  { name: 'Remaining Responses', value: 11 },
];

const Summary = () => {
  const { id, token } = useParams();
  const [loading, setLoading] = useState(true);
  const [boxesData, setBoxesData] = useState(boxes);

  const getData = async () => {
    if(token) {
      localStorage.setItem('token', token);
    }
    
    const response = await api.get('/summary/overview?survey_id='+id);
    if(response.data.status) {
      const data = response.data.data;
      const boxes = [
                    { name: 'Survey Views', value: data?.views },
                    { name: 'Survey Responses', value: data?.response },
                    { name: 'Amount Deducted', value: data?.deductAmount },
                    { name: 'Amount Remaining', value: data?.remainingAmount },
                    { name: 'Remaining Responses', value: data?.remainingResponse },
                  ];
                  setLoading(false)
                  setBoxesData(boxes); 
    }
    
  };

useEffect(() => {
    getData();
},[id]);


  return (
    <>
      <SingleQuestionNav id={id} />
      <div className="py-20 w-full px-3 mx-auto max-w-[1300px] sm:px-12 lg:px-20 grid gap-10">
        <div className="grid items-center gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
          {boxesData.map((box, index) => (
            <div
              key={`${index}-${box.name}`}
              className="flex items-center flex-col border text-center group/box hover:scale-105 transition-transform"
            >
              <p className="border-b w-full py-2 bg-primary text-white font-medium">
                {box.name}
              </p>
              <p className="py-4 text-2xl font-bold group-hover/box:bg-primary/20 w-full group-hover/box:text-primary transition-colors">
              {
                loading ? (<Skeleton.Button active size={'small'} shape={'square'} />):
                (<>{box.value}</>)
              }
              </p>
            </div>
          ))}
        </div>
        <BasicWordCloudPlot id={id} />
        <BasicPiePlot id={id} />
        <SummaryTable id={id} />
      </div>
    </>
  );
};

export default Summary;
