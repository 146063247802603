import React, { useEffect, useState } from 'react'
import { Rose } from '@ant-design/plots';
import api from '../utils/axios';



const defaultConfig = {
  data: [],
  yField: 'Option',
  xField: 'Responses',
  seriesField: 'type',
  radius: 0.9,
  legend: {
    position: 'bottom',
  },
};



const BasicRosePlot = ({ id, question, selectedChart }) => {

  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(defaultConfig);

  const getData = async () => {
    if (id !== 'undefined' && question !== 'undefined') {
      const response = await api.get('/questionWise/responses?survey_id=' + id + '&qnNo=' + question);
      if (response.data.status) {
        const data = response.data.data;
        if (data) {
          setConfig({
            data: data,
            yField: 'Option',
            xField: 'Responses',
            seriesField: 'type',
            radius: 0.9,
            legend: {
              position: 'bottom',
            },
          })
        }
        setLoading(false)

      }
    }

  };

  const refetchCheck = () => {
    if (selectedChart.includes('RoseChart')) {
      getData();
    }
  }

  useEffect(() => {
    refetchCheck();
  }, [selectedChart])

  useEffect(() => {
    getData()
  }, [id, question])


  return <Rose {...config} loading={loading} />;
};

export default BasicRosePlot
