import React, { useEffect, useState } from 'react';
import { Bar } from '@ant-design/plots';
import api from '../utils/axios';


const defaultConfig = {
  data: [],
  yField: 'Option',
  xField: 'Responses',
  yAxis: {
    label: {
      autoRotate: false,
    },
  },
  scrollbar: {
    type: 'vertical',
  },
  color: 'rgb(0, 107, 94)',
};


const BasicBarChart = ({ id, question, selectedChart }) => {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(defaultConfig);

  const getData = async () => {
    if (id !== 'undefined' && question !== 'undefined') {
      const response = await api.get('/questionWise/responses?survey_id=' + id + '&qnNo=' + question);
      if (response.data.status) {
        const data = response.data.data;
        if (data) {
          setConfig({
            data: data,
            yField: 'Option',
            xField: 'Responses',
            yAxis: {
              label: {
                autoRotate: false,
              },
            },
            scrollbar: {
              type: 'horizontal',
            },
            color: 'rgb(0, 107, 94)',
          })
        }
        setLoading(false)

      }
    }

  };

  const refetchCheck = () => {
    if (selectedChart.includes('BarChatVertical')) {
      getData();
    }
  }

  useEffect(() => {
    refetchCheck();
  }, [selectedChart])

  useEffect(() => {
    getData()
  }, [id, question])
  
  return <Bar {...config} loading={loading} className="flex-1" />;
};

export default BasicBarChart;
