import React, { useEffect, useState } from 'react';
import { Line } from '@ant-design/plots';
import api from '../utils/axios';


const defaultConfig = {
  data: [],
  padding: 'auto',
  xField: 'Option',
  yField: 'Responses',
  xAxis: {
    // type: 'timeCat',
    tickCount: 5,
  },
  slider: {
    start: 0.1,
    end: 0.5,
  },
  color: 'rgb(0, 107, 94)',
};


const BasicLinePlot = ({ id, question, selectedChart }) => {

  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(defaultConfig);

  const getData = async () => {
    if (id !== 'undefined' && question !== 'undefined') {
      const response = await api.get('/questionWise/responses?survey_id=' + id + '&qnNo=' + question);
      if (response.data.status) {
        const data = response.data.data;
        if (data) {
          setConfig({
            data: data,
            padding: 'auto',
            xField: 'Option',
            yField: 'Responses',
            xAxis: {
              // type: 'timeCat',
              tickCount: 5,
            },
            slider: {
              start: 0.1,
              end: 0.5,
            },
            color: 'rgb(0, 107, 94)',
          })
        }
        setLoading(false)

      }
    }


  };

  const refetchCheck = () => {
      if(selectedChart.includes('LineChart')) {
        getData();
      }
  }

  useEffect(() => {
    refetchCheck();
  }, [selectedChart])

  useEffect(() => {
    getData()
  }, [id, question])



  return <Line {...config} loading={loading} />;
};

export default BasicLinePlot;
