import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faBasketShopping,
  faPollH,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';

const links = [
  { name: 'My account', icon: faUserCircle, hiddenAtMobile: false, link: 'https://stage.numesond.com/pollster/account' },
  { name: 'My Surveys', icon: faPollH, hiddenAtMobile: false, link: 'https://stage.numesond.com/pollster/surveys' },
  { name: 'Wallet', icon: faBasketShopping, hiddenAtMobile: false, link: 'https://stage.numesond.com/pollster/wallet/history' },
  { name: 'Notifications', icon: faBell, hiddenAtMobile: true, link: 'https://stage.numesond.com/pollster/notifications' },
  // { name: 'Disconnect', icon: faPowerOff, hiddenAtMobile: false, link: '' },
];

const Navbar = () => {
  return (
    <header className="sticky top-0 py-[30px] w-full bg-white shadow-md z-50">
      <div className="flex items-center justify-between w-full px-3 mx-auto max-w-[1300px] sm:px-12 lg:px-20">
        <Link className="" to="https://stage.numesond.com/pollster/surveys">
          <img src="/numesond_logo_green.svg" alt="logo" />
          <span className="sr-only">Go to home page</span>
        </Link>
        <div className="flex gap-5 items-center">
          <div className="cursor-pointer max-[536px]:hidden">
            <FontAwesomeIcon
              icon={faBell}
              className="relative w-[30px] h-[30px]"
            />
            <span className="sr-only">Go to notifications page</span>
          </div>
          <div className="relative group/nav">
            <FontAwesomeIcon
              icon={faUserCircle}
              className="bg-primary peer text-white cursor-pointer w-[19px] h-[19px] rounded-full p-1.5"
            />
            <nav className="absolute w-60 hidden group-hover/nav:block bg-white z-50 shadow-lg top-8 transition-all max-2xl:right-0 2xl:left-1/2 2xl:-translate-x-1/2">
              <ul>
                {links.map((lk) => (
                  <li
                    className={`${
                      lk.hiddenAtMobile ? 'max-[535px]:flex hidden' : 'flex'
                    } items-center cursor-pointer gap-4 py-4 px-10 border-b group/item hover:border-l-4 transition-all border-l-primary hover:bg-slate-100`}
                  >
                    <FontAwesomeIcon
                      icon={lk.icon}
                      className="text-2xl group-hover/item:text-primary"
                    />
                    <p className="text-slate-600 font-medium group-hover/item:text-black">
                      <a href={lk.link}> {lk.name} </a>
                    </p>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
