import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Pie } from '@ant-design/plots';
import './Charts.css';

const DemoPie = () => {
  const data = [
    {
      type: '分类一',
      value: 27,
    },
    {
      type: '分类二',
      value: 25,
    },
    {
      type: '分类三',
      value: 18,
    },
    {
      type: '分类四',
      value: 15,
    },
    {
      type: '分类五',
      value: 10,
    },
    {
      type: '其他',
      value: 5,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.6,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return (
    <>
      <div className="mainDiv3">
        {/* <div style={{display:"flex",flexDirection:"row"}}>
            <div style={{marginRight:"50%",width:"50%"}}> 
            <h3>What is this?</h3>
        <h4>5 Responses</h4></div>
        <div style={{width:"50%"}}>
        <h3>What is this?</h3>
        <h4>5 Responses</h4>
        </div>
        </div>
        <div className='mainDiv4'>
  <Pie {...config} />
  <Pie {...config} />
  </div> */}

        <div className="quiz_one">
          <div style={{ marginRight: '50%', width: '100%' }}>
            <h3>What is this?</h3>
            <h4>5 Responses</h4>
          </div>
          <div>
            <Pie {...config} />
          </div>
        </div>

        {/* quiz two */}

        <div className="quiz_2">
          <div style={{ width: '100%' }}>
            <h3>What is this?</h3>
            <h4>5 Responses</h4>
          </div>
          <div>
            <Pie {...config} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoPie;
