import React, { useEffect, useState } from 'react';
import { Gauge } from '@ant-design/plots';
import api from '../utils/axios';

const defaultConfig = {
  percent: 0,
  range: {
    color: 'rgb(0, 107, 94)',
  },
  indicator: null,
  axis: {
    label: {
      formatter(v) {
        return Number(v) * 100;
      },
    },
    subTickLine: {
      count: 3,
    },
  },
  statistic: {
    title: {
      offsetY: -36,
      style: {
        fontSize: '36px',
        color: '#4B535E',
      },
      formatter: () => '70%',
    },
    content: {
      style: {
        fontSize: '24px',
        lineHeight: '44px',
        color: '#4B535E',
      },
      formatter: () => 'Submissions',
    },
  },
};


const BasicGaugePlot = ({ loading , data }) => {
  const [config, setConfig] = useState(defaultConfig);

  const getData = async () => {

      const response = data?.responseRate ?? 0;
      setConfig({
        percent: response / 100,
        range: {
          color: 'rgb(0, 107, 94)',
        },
        indicator: null,
        axis: {
          label: {
            formatter(v) {
              return Number(v) * 100;
            },
          },
          subTickLine: {
            count: 3,
          },
        },
        statistic: {
          title: {
            offsetY: -36,
            style: {
              fontSize: '36px',
              color: '#4B535E',
            },
            formatter: () => `${(response).toFixed(3)}%`,
          },
          content: {
            style: {
              fontSize: '24px',
              lineHeight: '44px',
              color: '#4B535E',
            },
            formatter: () => 'Submissions',
          },
        },
      })

  };

  useEffect(() => { getData() }, [data]);
  
  return <Gauge {...config} loading={loading} className="flex-1" />;
};

export default BasicGaugePlot;
