import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api-stage.numesond.com/node/reports/v1',
  // baseURL: 'http://localhost:9000/v1'
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;