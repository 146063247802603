import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';

import './App.css';

import RouteLayout from './layouts/RootLayout';
import Home from './routes/Home';
import Summary from './routes/Summary';
import Question from './routes/Question';
// import Individual from './routes/Individual';
import Dashboards from './routes/Dashboard';
import Tablets from './routes/Tablets';
import { Login } from '@mui/icons-material';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RouteLayout />}>
      <Route index element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/:id/summary/:token" element={<Summary />} />
      <Route path="/:id/summary" element={<Summary />} />
      <Route path="/:id/question" element={<Question />} />
      {/* <Route path="/individual" element={<Individual />} /> */}
      <Route path="/dashboard" element={<Dashboards />} />
      <Route path="/dashboard/:token" element={<Dashboards />} />
      <Route path="/tablets" element={<Tablets />} />
    </Route>
  )
);

function App() {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: '#006b5e',
            borderRadius: 0,

            // Alias Token
            colorBgContainer: '#fff',
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </>
  );
}

export default App;
