import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import "./DropDown.css";
import { Button } from 'antd';
const items = [
  {
    label: "1st menu item",
    key: '0',
  },
  {
    label:"2nd menu item",
    key: '1',
  },
   {
     type: 'divider',
  },
  {
    label: '3rd menu item',
    key: '3',
  },
];
const Dropdowns = () => {
    return(
    <>
    <div >
  <Dropdown type="primary"
  size="large"
    menu={{
      items,
    }}
    trigger={['click']}
    className='dropdown'
  >
    <a onClick={(e) => e.preventDefault()}>
      <Space>
        Select Survey
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
  
</div>
  </>
    )
};
export default Dropdowns;