import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import Charts from '../components/Charts';
import SingleQuestionNav from '../components/SingleQuestionNav';
import { useParams } from 'react-router-dom';
import api from '../utils/axios';
import BasicLinePlot from '../components/BasicLinePlot';
import StepLinePlot from '../components/StepLinePlot';
import BasicBarChart from '../components/BasicBarChart';
import { questionWiseChartTypes } from '../utils/constants';
import BasicColumnPlot from '../components/BasicColumnPlot';
import BasicAreaPlot from '../components/BasicAreaPlot';
import BasicRosePlot from '../components/BasicRosePlot';
import BasicTable from '../components/BasicTable';


const onSearch = (value) => {
  console.log('search:', value);
};

// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const Question = () => {
  const { id } = useParams();
  const [questions, setQuestion] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedChart, setSelectedChart] = useState([]);
  const [chartOptions, setChartOptions] = useState([]);

const getInitialData = async () => {
  try {
      const response = await api.get('/questionWise/questions?survey_id='+id);
      if(response.data.data && response.data.data.length > 0) {
        const options = response.data.data.map((ele, i) => {
          return {
            value: i+1,
            type: ele.type,
            label: ele.question,
          }
        });
        setQuestion(options);
      }
      setLoading(false);
  } catch (err) {
    
  }
}

useEffect(() => {getInitialData()}, [id]);


const handleSelectQuestion = (value) => {
  setSelectedQuestion(value);
  setSelectedChart([]);
  const selectedQuestion = questions.filter((ele) => ele.value === value)
  setChartOptions(questionWiseChartTypes[selectedQuestion[0].type])
 
}


  return (
    <>
      <SingleQuestionNav id={id} />
      <div className="py-20 w-full px-3 mx-auto max-w-[1300px] sm:px-12 lg:px-20 grid gap-10">
        <div className="grid gap-4 w-full md:grid-cols-2">
          <Select
            showSearch
            size="large"
            placeholder="Select a question"
            optionFilterProp="children"
            onChange={(ele) => handleSelectQuestion(ele)}
            onSearch={onSearch}
            filterOption={filterOption}
            className="w-full"
            loading={loading}
            options={questions}
          />
          <Select
            showSearch
            size="large"
            placeholder="Select graph type"
            optionFilterProp="children"
            onChange={(ele) => setSelectedChart(ele)}
            onSearch={onSearch}
            filterOption={filterOption}
            className=""
            mode="multiple"
            options={chartOptions}
              
            //   [
            //   {
            //     value: 'LineChart',
            //     label: 'Line chart',
            //   },
            //   {
            //     value: 'StepLineChart',
            //     label: 'Step line chart',
            //   },
            //   {
            //     value: 'BarChatVertical',
            //     label: 'Bar chat vertical',
            //   },
            //   // {
            //   //   value: 'BarChatHorizontal',
            //   //   label: 'Bar chat horizontal',
            //   // },
            // ]}

          />
        </div>
       {
        selectedChart.includes('LineChart') ?
        ( <BasicLinePlot id={id} question={selectedQuestion} selectedChart={selectedChart} />) : undefined}

      {  selectedChart.includes('StepLineChart') ?
        (  <StepLinePlot id={id} question={selectedQuestion} selectedChart={selectedChart} />): undefined}

        {selectedChart.includes('BarChatVertical') ?
        ( <BasicBarChart id={id} question={selectedQuestion} selectedChart={selectedChart} />) : undefined }
        {selectedChart.includes('ColumnChart') ? 
        (<BasicColumnPlot id={id} question={selectedQuestion} selectedChart={selectedChart} />): undefined  }
        {selectedChart.includes('AreaChart') ? 
        (<BasicAreaPlot id={id} question={selectedQuestion} selectedChart={selectedChart} />): undefined  }
        {selectedChart.includes('RoseChart') ? 
        (<BasicRosePlot id={id} question={selectedQuestion} selectedChart={selectedChart} />): undefined  }
        {selectedChart.includes('Table') ? 
        (<BasicTable id={id} question={selectedQuestion} selectedChart={selectedChart} />): undefined  }
        {/* <Charts /> */}
      </div>
    </>
  );
};

export default Question;
