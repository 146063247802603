import React from 'react';

import Cards from '../components/Card';
import Charts from '../components/chart';
import DemoLine from '../components/cart2';
import '../components/Charts.css';
import Tables from '../components/Table1';
import Dropdowns from '../components/DropDown';
import DemoPie from '../components/Cahrt3';

const Tablets = () => {
  return (
    <>
      <div className="tablets_">
        <div style={{ marginTop: '10%' }}>
          <Dropdowns />
        </div>
        <div>
          <Cards />
        </div>
        <div>
          <Tables />
        </div>

        <DemoPie />
      </div>
    </>
  );
};
export default Tablets;
