import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import api from '../utils/axios';

const defaultConfig = {
  data: [],
  // padding: 'auto',
  xField: 'Option',
  yField: 'Responses',
  xAxis: {
    label: {
      autoRotate: false,
    },
  },
  slider: {
    start: 0.1,
    end: 0.2,
  },
  color: 'rgb(0, 107, 94)',
};

const BasicColumnPlot = ({ id, question, selectedChart }) => {

  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(defaultConfig);

  const getData = async () => {
    if (id !== 'undefined' && question !== 'undefined') {
      const response = await api.get('/questionWise/responses?survey_id=' + id + '&qnNo=' + question);
      if (response.data.status) {
        const data = response.data.data;
        if (data) {
          setConfig({
            data: data,
            // padding: 'auto',
            xField: 'Option',
            yField: 'Responses',
            xAxis: {
              label: {
                autoRotate: false,
              },
            },
            slider: {
              start: 0.1,
              end: 0.2,
            },
            color: 'rgb(0, 107, 94)',
          })
        }
        setLoading(false)

      }
    }


  };

  const refetchCheck = () => {
    if (selectedChart.includes('ColumnChart')) {
      getData();
    }
  }

  useEffect(() => {
    refetchCheck();
  }, [selectedChart])

  useEffect(() => {
    getData()
  }, [id, question])


  return <Column {...config} loading={loading} />;
};

export default BasicColumnPlot;
