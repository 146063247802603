export const questionWiseChartTypes = {
    MultipleChoices: [
        {
            value: 'LineChart',
            label: 'Line Chart'
        },
        {
            value: 'ColumnChart',
            label: 'Column Chart'
        },
        {
            value: 'AreaChart',
            label: 'Area Chart'
        },
        {
            value: 'BarChatVertical',
            label: 'Bar chat vertical'
        },
        {
            value: 'PieChart',
            label: 'Pie Chart'
        },
        {
            value: 'RoseChart',
            label: 'Rose Chart'
        }
    ],
    DropDown: [
        {
            value: 'LineChart',
            label: 'Line Chart'
        },
        {
            value: 'ColumnChart',
            label: 'Column Chart'
        },
        {
            value: 'AreaChart',
            label: 'Area Chart'
        },
        {
            value: 'BarChatVertical',
            label: 'Bar chat vertical'
        },
        {
            value: 'PieChart',
            label: 'Pie Chart'
        },
        {
            value: 'RoseChart',
            label: 'Rose Chart'
        }
    ],
    ShortText: [
        {
            value: 'Table',
            label: 'Table view'
        }
    ],
    LongQuestion: [
        {
            value: 'Table',
            label: 'Table view'
        }
    ],
    PictureSelect: [
        {
            value: 'LineChart',
            label: 'Line Chart'
        },
        {
            value: 'ColumnChart',
            label: 'Column Chart'
        },
        {
            value: 'AreaChart',
            label: 'Area Chart'
        },
        {
            value: 'BarChatVertical',
            label: 'Bar chat vertical'
        },
        {
            value: 'PieChart',
            label: 'Pie Chart'
        },
        {
            value: 'RoseChart',
            label: 'Rose Chart'
        }
    ],
    YesOrNo: [
        {
            value: 'LineChart',
            label: 'Line Chart'
        },
        {
            value: 'ColumnChart',
            label: 'Column Chart'
        },
        {
            value: 'AreaChart',
            label: 'Area Chart'
        },
        {
            value: 'BarChatVertical',
            label: 'Bar chat vertical'
        },
        {
            value: 'PieChart',
            label: 'Pie Chart'
        },
        {
            value: 'RoseChart',
            label: 'Rose Chart'
        }
    ],
    'Rank Order': [
        {
            value: 'LineChart',
            label: 'Line Chart'
        },
        {
            value: 'ColumnChart',
            label: 'Column Chart'
        },
        {
            value: 'AreaChart',
            label: 'Area Chart'
        },
        {
            value: 'BarChatVertical',
            label: 'Bar chat vertical'
        },
        {
            value: 'PieChart',
            label: 'Pie Chart'
        },
        {
            value: 'RoseChart',
            label: 'Rose Chart'
        }
    ]

};