import React, { useEffect, useState } from 'react';
import { Bar } from '@ant-design/plots';
import api from '../utils/axios';


const defaultConfig = {
  data: [],
  yField: 'Survey',
  xField: 'Responses',
  yAxis: {
    label: {
      autoRotate: false,
    },
  },
  scrollbar: {
    type: 'vertical',
  },
  color: 'rgb(0, 107, 94)',
};


const DashboardBarChart = ({ loading, data }) => {
  const [config, setConfig] = useState(defaultConfig);

  const setData = () => {
    setConfig({
      data,
      yField: 'Survey',
      xField: 'Responses',
      yAxis: {
        label: {
          autoRotate: false,
        },
      },
      scrollbar: {
        type: 'vertical',
      },
      color: 'rgb(0, 107, 94)',
    })
  }

  useEffect(() => {setData()}, [data]);
  
  return <Bar {...config} loading={loading} className="flex-1" />;
};

export default DashboardBarChart;
