import React, { useEffect, useState } from 'react';
import { WordCloud } from '@ant-design/plots';
import api from '../utils/axios';

const countries = [
  {
    x: 'China',
    value: 0,
    category: 'asia',
  },
  {
    x: 'India',
    value: 0,
    category: 'asia',
  },
  {
    x: 'United States',
    value: 0,
    category: 'america',
  },
  {
    x: 'Indonesia',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Brazil',
    value: 0,
    category: 'america',
  },
  {
    x: 'Pakistan',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Nigeria',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Bangladesh',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Russia',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Japan',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Mexico',
    value: 0,
    category: 'america',
  },
  {
    x: 'Ethiopia',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Philippines',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Egypt',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Vietnam',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Germany',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Democratic Republic of the Congo',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Iran',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Turkey',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Thailand',
    value: 0,
    category: 'asia',
  },
  {
    x: 'France',
    value: 0,
    category: 'europe',
  },
  {
    x: 'United Kingdom',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Italy',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Tanzania',
    value: 0,
    category: 'africa',
  },
  {
    x: 'South Africa',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Myanmar',
    value: 0,
    category: 'asia',
  },
  {
    x: 'South Korea',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Colombia',
    value: 0,
    category: 'america',
  },
  {
    x: 'Kenya',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Spain',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Argentina',
    value: 0,
    category: 'america',
  },
  {
    x: 'Ukraine',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Sudan',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Uganda',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Algeria',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Poland',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Iraq',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Canada',
    value: 0,
    category: 'america',
  },
  {
    x: 'Morocco',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Saudi Arabia',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Uzbekistan',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Malaysia',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Peru',
    value: 0,
    category: 'america',
  },
  {
    x: 'Venezuela',
    value: 0,
    category: 'america',
  },
  {
    x: 'Nepal',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Angola',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Ghana',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Yemen',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Afghanistan',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Mozambique',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Australia',
    value: 0,
    category: 'australia',
  },
  {
    x: 'North Korea',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Cameroon',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Ivory Coast',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Madagascar',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Niger',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Sri Lanka',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Romania',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Burkina Faso',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Syria',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Mali',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Malawi',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Chile',
    value: 0,
    category: 'america',
  },
  {
    x: 'Kazakhstan',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Netherlands',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Ecuador',
    value: 0,
    category: 'america',
  },
  {
    x: 'Guatemala',
    value: 0,
    category: 'america',
  },
  {
    x: 'Zambia',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Cambodia',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Senegal',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Chad',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Zimbabwe',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Guinea',
    value: 0,
    category: 'africa',
  },
  {
    x: 'South Sudan',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Rwanda',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Belgium',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Tunisia',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Cuba',
    value: 0,
    category: 'america',
  },
  {
    x: 'Bolivia',
    value: 0,
    category: 'america',
  },
  {
    x: 'Somalia',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Haiti',
    value: 0,
    category: 'america',
  },
  {
    x: 'Greece',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Benin',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Czech Republic',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Portugal',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Burundi',
    value: 0,
    category: 'africa',
  },
  {
    x: 'Dominican Republic',
    value: 0,
    category: 'america',
  },
  {
    x: 'Sweden',
    value: 0,
    category: 'europe',
  },
  {
    x: 'United Arab Emirates',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Jordan',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Azerbaijan',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Hungary',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Belarus',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Honduras',
    value: 0,
    category: 'america',
  },
  {
    x: 'Austria',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Tajikistan',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Israel',
    value: 0,
    category: 'asia',
  },
  {
    x: 'Switzerland',
    value: 0,
    category: 'europe',
  },
  {
    x: 'Papua New Guinea',
    value: 0,
    category: 'australia',
  },
];

const defaultConfig = {
  data: countries,
  wordField: 'x',
  weightField: 'value',
  color: 'rgb(0, 107, 94)',
  wordStyle: {
    fontFamily: 'Verdana',
    fontSize: [24, 80],
  },
  interactions: [
    {
      type: 'element-active',
    },
  ],
  state: {
    active: {
      style: {
        lineWidth: 3,
      },
    },
  },
};

const BasicWordCloudPlot = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(defaultConfig);

  const getData = async () => {
    const response = await api.get('/summary/world?survey_id='+id);
    if(response.data.status) {
      const data = response.data.data;
      if(data) {
        let count = {};
        data.forEach((value) => {
        count[value] = (count[value] || 0) + 1;
        });

    const citiesWithCount = Object.keys(count).map((key) => {
          return { key: key, count: count[key] };
      });

    const newList = countries.map((value) => {
          
        const isExists = citiesWithCount.filter(ele => ele.key.toLowerCase() === value.x.toLowerCase());
        if(isExists.length > 0) {
          return {
            x: value.x,
            value: isExists[0].count,
            category: value.category,
          }
        }else return value;
      });
      setConfig({
        data: newList,
        wordField: 'x',
        weightField: 'value',
        color: 'rgb(0, 107, 94)',
        wordStyle: {
          fontFamily: 'Verdana',
          fontSize: [24, 80],
        },
        interactions: [
          {
            type: 'element-active',
          },
        ],
        state: {
          active: {
            style: {
              lineWidth: 3,
            },
          },
        },
      })

      }   
        setLoading(false)

    }
    
  };
  
  useEffect(() => {
getData()
  }, [id])
  

  return <WordCloud {...config} loading={loading} />;
};

export default BasicWordCloudPlot;
