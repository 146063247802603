import React from 'react';
import { Card } from 'antd';
import './Card.css';
const Cards = () => {
  return (
    <>
      <div className="CardDiv">
        <div className="CardDiv2">
          <Card
            size="small"
            title="Survey views"
            hoverable="true"
            style={{
              width: 200,
            }}
            className="card card2"
          >
            <h1>12</h1>
          </Card>

          <Card
            size="small"
            title="Survey Responses"
            hoverable="true"
            style={{
              width: 200,
            }}
            className="card card1"
          >
            <h1>13</h1>
          </Card>

          <Card
            size="small"
            title="Amount deducted"
            hoverable="true"
            style={{
              width: 200,
            }}
            className="card card1 card4"
          >
            <h1>1400</h1>
          </Card>

          <Card
            size="small"
            title="Amount remaining"
            hoverable="true"
            style={{
              width: 200,
            }}
            className="card card1 card5"
          >
            <h1>1500</h1>
          </Card>

          <Card
            size="small"
            title="Remaining Responses"
            hoverable="true"
            style={{
              width: 200,
            }}
            className="card"
          >
            <h1 style={{ textAlign: 'center' }}>11</h1>
          </Card>
        </div>
      </div>
    </>
  );
};
export default Cards;
