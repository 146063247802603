import React, { useState, useRef, useEffect } from 'react';
import { Search } from '@mui/icons-material';
import { Table, Button, Input, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import api from '../utils/axios';

const SummaryTable = ({ id }) => {
  const [isReady, setIsReady] = useState(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const response = await api.get('/summary/detail?survey_id='+id);
    if(response.data.status) {
      const resData = response.data.data;
      if(resData && resData.length > 0) {
          setData(resData);

      }   
        setLoading(false)

    }
    
  };
  
  useEffect(() => {
getData()
  }, [id])
  

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<Search />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <Search
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      ...getColumnSearchProps('question'),
      sorter: (a, b) => a.question.length - b.question.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Question type',
      dataIndex: 'type',
      key: 'type',
      ...getColumnSearchProps('type'),
      sorter: (a, b) => a.type.length - b.type.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'No. of responses',
      dataIndex: 'responses',
      key: 'responses',
      ...getColumnSearchProps('responses'),
      sorter: (a, b) => a.responses.length - b.responses.length,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  return (
    <Table
      bordered={true}
      columns={columns}
      dataSource={data}
      pagination={{
        pageSize: 25,
      }}
      scroll={{
        y: 240,
      }}
      className="flex-1"
    />
  );
};
export default SummaryTable;
