import React, { useEffect, useState } from 'react';
import { Bar } from '@ant-design/plots';
import api from '../utils/axios';

const data = [
  {
    survey: 'Survey 1',
    status: 'Completed',
    value: 502,
  },
  {
    survey: 'Survey 1',
    status: 'Incomplete',
    value: 635,
  },
  {
    survey: 'Survey 2',
    status: 'Completed',
    value: 809,
  },
  {
    survey: 'Survey 2',
    status: 'Incomplete',
    value: 947,
  },
  {
    survey: 'Survey 3',
    status: 'Completed',
    value: 1402,
  },
  {
    survey: 'Survey 3',
    status: 'Incomplete',
    value: 3634,
  },
  {
    survey: 'Survey 4',
    status: 'Completed',
    value: 5268,
  },
  {
    survey: 'Survey 4',
    status: 'Incomplete',
    value: 106,
  },
  {
    survey: 'Survey 5',
    status: 'Completed',
    value: 107,
  },
  {
    survey: 'Survey 5',
    status: 'Incomplete',
    value: 111,
  },
  {
    survey: 'Survey 6',
    status: 'Completed',
    value: 133,
  },
  {
    survey: 'Survey 6',
    status: 'Incomplete',
    value: 221,
  },
];

const defaultConfig = {
  data: [],
  xField: 'value',
  yField: 'survey',
  seriesField: 'status',
  isPercent: true,
  isStack: true,
  color: ['rgb(0, 107, 94)', '#9fadab'],
  label: {
    position: 'middle',
    content: (item) => {
      return item.value.toFixed(2);
    },
    style: {
      fill: 'transparent',
    },
  },
};

export default function PercentBarPlot({ loading, data }) {
  const [config, setConfig] = useState(defaultConfig);

  const getData = async () => {
      const value = data?.data;
      setConfig({
        data: value,
        xField: 'value',
        yField: 'survey',
        seriesField: 'status',
        isPercent: true,
        isStack: true,
        color: ['rgb(0, 107, 94)', '#9fadab'],
        label: {
          position: 'middle',
          content: (item) => {
            return item.value.toFixed(2);
          },
          style: {
            fill: 'transparent',
          },
        },
      })
    }

  useEffect(() => { getData() }, [data]);

  return (
    <>
      <Bar {...config} loading={loading} className="flex-1" />
    </>
  );
}
