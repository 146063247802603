import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import api from '../utils/axios';

const BasicTable = ({ id, question, selectedChart }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const getData = async () => {
        if (id !== 'undefined' && question !== 'undefined') {
            const response = await api.get('/questionWise/responses?survey_id=' + id + '&qnNo=' + question);
            if (response.data.status) {
                setData(response.data.data);
                setLoading(false)

            }
        }

    };

    const refetchCheck = () => {
        if (selectedChart.includes('Table')) {
            getData();
        }
    }

    useEffect(() => {
        refetchCheck();
    }, [selectedChart])

    useEffect(() => {
        getData()
    }, [id, question])


    const columns = [
        {
            title: 'Response',
            dataIndex: 'Option',
            key: 'Option',
        },
        // {
        //     title: 'Responses',
        //     dataIndex: 'Responses',
        //     key: 'Responses',
        // }
    ];

    return (
        <Table
            bordered={true}
            columns={columns}
            dataSource={data}
            pagination={{
                pageSize: 25,
            }}
            // scroll={{
            //     y: 240,
            // }}
            className="flex-1"
            loading={loading}
        />
    );
};
export default BasicTable;
