import React, { useEffect, useState } from 'react';
import { Pie } from '@ant-design/plots';
import api from '../utils/axios';

const defaultConfig = {
  appendPadding: 10,
  data: [
    {
      type: 'Male',
      value: 0,
    },
    {
      type: 'Female',
      value: 0,
    },
  ],
  angleField: 'value',
  colorField: 'type',
  radius: 0.75,
  label: {
    type: 'spider',
    labelHeight: 28,
    content: '{name}\n{percentage}',
  },
  interactions: [
    {
      type: 'element-selected',
    },
    {
      type: 'element-active',
    },
  ],
};

const BasicPiePlot = ({ id }) => {
  const [config, setConfig] = useState(defaultConfig);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const response = await api.get('/summary/gender?survey_id=' + id);
    if (response.data.status) {
      const data = response.data.data;
      let count = {};
      data.forEach((value) => {
        count[value] = (count[value] || 0) + 1;
      });

      const dataWithCount = Object.keys(count).map((key) => {
        return { key: key, count: count[key] };
      });

      const male = dataWithCount.filter(ele => ele.key === 'M');
      const female = dataWithCount.filter(ele => ele.key !== 'M');

      setConfig({
        appendPadding: 10,
        data: [
          {
            type: 'Male',
            value: male.length > 0 ? male[0].count: 0,
          },
          {
            type: 'Female',
            value: female.length > 0 ? female[0].count: 0,
          },
        ],
        angleField: 'value',
        colorField: 'type',
        radius: 0.75,
        label: {
          type: 'spider',
          labelHeight: 28,
          content: '{name}\n{percentage}',
        },
        interactions: [
          {
            type: 'element-selected',
          },
          {
            type: 'element-active',
          },
        ],
      })

      setLoading(false)
    }

  };

  useEffect(() => {getData()}, [id])

  return <Pie {...config} loading={loading} />;
};

export default BasicPiePlot;
