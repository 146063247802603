import React, { useEffect, useState } from 'react';
import { Checkbox, DatePicker, Divider, Skeleton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullScreen } from '@chiragrupani/fullscreen-react';

import PercentBarPlot from '../components/PercentBarPlot';
import BasicGaugePlot from '../components/BasicGaugePlot';
import BasicHeatMap from '../components/BasicHeatMap';
import DashboardBarChart from '../components/DashboardBarChart';
import BasicDonutPlot from '../components/BasicDonutPlot';
import SummaryTable from '../components/SummaryTable';
import {
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import api from '../utils/axios';
import moment from 'moment';

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

// Range Setup
const dateFormat = 'YYYY-MM-DD';

const defaultResponseData = {
  surveyCount: 0,
  totalQuestions: 0,
  responseCount: 0,
  responseQuestions: 0
}

const defaultData2 = {
  data: [],
  responseRate: 0,
  averageResponseTime: "0 minutes"
}

const Dashboards = () => {
  const { token } = useParams();

  const [dateRange, setDateRange] = useState([moment().subtract(30, 'd').format(dateFormat), moment().format(dateFormat)]);
  const [loading, setLoading] = useState(true);
  const [surveyList, setSurveyList] = useState([]);
  const [surveyOptions, setSurveyOptions] = useState([]);
  const [responseData, setResponseData] = useState(defaultResponseData);

  const [data2, setData2] = useState(defaultData2);
  const [loading2, setLoading2] = useState(true);
  const [heatMapData, setHeatMapData] = useState([]);
  const [topSurveysData, setTopSurveysData] = useState([]);

  const [filterSurveys, setFilterSurveys] = useState([]);

  const [isFullScreen, setFullScreen] = useState(false);

  const [checkedList, setCheckedList] = useState([]);
  const checkAll = surveyOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < surveyOptions.length;

  const onCheckListChange = (list) => {
    setCheckedList(list);
    getData(list);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? surveyOptions : []);
    if (e.target.checked) {
      getData(surveyOptions);
    }else {
      getData([]);
    }
  };

  const onChangeDate = (change, dateString) => {
    if (change !== null) {
      setDateRange(dateString);
    }
  }
  const getData = async (checkList = [], isFirstTime = false) => {
    // setSurveyOptions([]);
    setResponseData(defaultResponseData);
    // setSurveyList([]);
    // setCheckedList([]);
    if(!isFirstTime) {
      setLoading(true);
    }
    if (token) {
      localStorage.setItem('token', token);
    }
    const surveyIds = surveyList
      .filter(ele => checkList.includes(ele.surveyName))
      .map(ele => ele.surveyId);
     
      if(!isFirstTime) {
        setFilterSurveys(surveyIds);
      }

    const payload = {
      startDate: dateRange[0], endDate: dateRange[1], surveyIds, isFirstTime
    }
    const response = await api.post('/dashboard/summary', payload);
    if (response.data.status) {
      const data = response.data.data;
      setSurveyList(data.surveyList);
      const surveyOptions = data.surveyList?.map(ele => ele.surveyName);
      setSurveyOptions(surveyOptions);
      if (isFirstTime) {
        setCheckedList(surveyOptions)
        setFilterSurveys(data.surveyList.map(ele => ele.surveyId))
      }

      setResponseData(data);
      setLoading(false)
    }

  };

  useEffect(() => { getData([], true) }, [dateRange]);

  /***************************************************************/

  const getData2 = async () => {
    setLoading2(true);
    const response = await api.post('/dashboard/responseRate', { surveyIds: filterSurveys });
    if (response.data.status) {
      const data = response.data?.data;
      setData2(data);
      setLoading2(false);
    }
  };

  const getHeatMapData = async () => {
    setLoading2(true);
    const response = await api.post('/dashboard/heatmap', { surveyIds: filterSurveys });
    if (response.data.status) {
      const data = response.data?.data;
      setHeatMapData(data);
      setLoading2(false);
    }
  };

  const getTopSurveys = async () => {
    setLoading2(true);
    const response = await api.post('/dashboard/topSurveys', { surveyIds: filterSurveys });
    if (response.data.status) {
      const data = response.data?.data;
      setTopSurveysData(data);
      setLoading2(false);
    }
  };

  useEffect(() => {getData2(); getHeatMapData(); getTopSurveys()}, [filterSurveys]);







  return (
    <div className="w-full p-8">
      <FullScreen
        isFullScreen={isFullScreen}
        onChange={(isFull) => {
          setFullScreen(isFull);
        }}
      >
        <div className="bg-white">
          <header className="bg-white w-full p-8 flex text-4xl justify-center gap-8 shadow-lg mb-4">
            <p className="font-semibold">Survey Overview Dashboard</p>
            <button onClick={(e) => setFullScreen(!isFullScreen)}>
              {isFullScreen ? (
                <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
              ) : (
                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
              )}
            </button>
          </header>
          <main
            className={`grid gap-8 grid-flow-row bg-white ${isFullScreen ? 'h-screen overflow-y-scroll p-8 pb-36' : ''
              }`}
          >
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
              <div className="grid place-content-center p-4 gap-2 h-44 shadow-lg">
                <p className="font-medium">Created Date</p>
                <RangePicker onChange={onChangeDate} format={dateFormat} />
              </div>
              <div className="flex flex-col p-4 gap-2 h-44 shadow-lg">
                <p className="font-medium text-left">Survey List</p>
                <div className="flex flex-col w-full h-full overflow-y-scroll">
                  {
                    loading ?
                      (<Skeleton active />) :
                      (
                        <>
                          <Checkbox
                            indeterminate={indeterminate}
                            onChange={onCheckAllChange}
                            checked={checkAll}
                            className="px-4 py-2 hover:bg-border"
                          >
                            All
                          </Checkbox>
                          <CheckboxGroup
                            options={surveyOptions}
                            value={checkedList}
                            onChange={onCheckListChange}
                            className="flex-col custom-checkbox-wrapper"
                          />
                        </>
                      )
                  }


                </div>
              </div>
              <div className="grid place-content-center p-4 gap-4 h-44 shadow-lg">
                <p className="text-center font-medium">Surveys</p>
                <div className="flex gap-2">
                  <div className="grid gap-1 justify-items-end">
                    <p className="font-bold text-lg">{responseData?.surveyCount ?? 0}</p>
                    <p>Created</p>
                  </div>
                  <Divider type="vertical" className="h-full bg-border" />
                  <div className="grid gap-1 justify-items-start">
                      <p className="font-bold text-lg">{responseData?.totalQuestions ?? 0}</p>
                      <p>Questions</p>
                    </div>
                 
                </div>
              </div>
              <div className="grid place-content-center p-4 gap-4 h-44 shadow-lg">
                <p className="text-center font-medium">Responses</p>
                <div className="flex gap-2">
                  <div className="grid gap-1 justify-items-end">
                    <p className="font-bold text-lg">{responseData?.responseCount ?? 0}</p>
                    <p>Created</p>
                  </div>
                  <Divider type="vertical" className="h-full bg-border" />
                  <div className="grid gap-1 justify-items-start">
                    <p className="font-bold text-lg">{responseData?.responseQuestions ?? 0}</p>
                    <p>Questions</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid gap-8 lg:grid-cols-4 lg:grid-rows-3">
              <div className="flex relative flex-col p-4 gap-2 shadow-lg lg:col-span-3 lg:row-span-3">
                <p className="font-medium">Response Rate by Survey</p>
                <PercentBarPlot loading={loading2} data={data2}  />
              </div>
              <div className="flex relative flex-col p-4 gap-2 shadow-lg lg:row-span-2">
                <p className="font-medium">Response Rate</p>
                <BasicGaugePlot loading={loading2} data={data2} />
              </div>
              <div className="grid p-4 gap-2 shadow-lg">
                <p className="font-medium">Average Survey Completion Time</p>
                <p className="font-semibold text-5xl">{ data2?.averageResponseTime }</p>
              </div>
            </div>
            <div className="grid gap-8 lg:grid-cols-5">
              <div className="flex relative flex-col p-4 gap-2 shadow-lg lg:col-span-3">
                <p className="font-medium">Survey Responses Over Time</p>
                <BasicHeatMap loading={loading2} data={heatMapData}  />
              </div>
              <div className="flex relative flex-col p-4 gap-2 shadow-lg lg:col-span-2">
                <p className="font-medium">Top 5 Completed Surveys</p>
                <DashboardBarChart loading={loading2} data={topSurveysData} />
              </div>
            </div>
            {/* <div className="grid gap-8 lg:grid-cols-5">
              <div className="flex relative flex-col p-4 gap-2 shadow-lg lg:col-span-2">
                <p className="font-medium">Surveys by Category</p>
                <BasicDonutPlot />
              </div>
              <div className="flex relative flex-col p-4 gap-2 shadow-lg lg:col-span-3">
                <p className="font-medium">Survey Summary</p>
                <SummaryTable />
              </div>
            </div> */}
          </main>
        </div>
      </FullScreen>
    </div>
  );
};
export default Dashboards;
