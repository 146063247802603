import React, { useEffect, useState } from 'react';
import { Heatmap } from '@ant-design/plots';

const defaultConfig = {
  data: [],
  xField: 'Hour',
  yField: 'Day',
  colorField: 'Responses',
  color: ['#9fadab', 'rgb(0, 107, 94)'],
  autoFit: false,
  meta: {
    Hour: {
      type: 'cat',
    },
  },
};

export default function BasicHeatMap({ loading, data }) {
  const [config, setConfig] = useState(defaultConfig);

  const setData = () => {
    setConfig({
      data,
      xField: 'Hour',
      yField: 'Day',
      colorField: 'Responses',
      color: ['#9fadab', 'rgb(0, 107, 94)'],
      autoFit: false,
      meta: {
        Hour: {
          type: 'cat',
        },
      },
    })
  }

  useEffect(() => {setData()}, [data]);
  

  return <Heatmap {...config} loading={loading} className="flex-1" />;
}
