import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const tabs = [
  { name: 'Summary', pathname: 'summary' },
  { name: 'Question', pathname: 'question' },
  // { name: 'Individual', pathname: 'individual' },
];

const baseLinkStyle =
  'py-3 px-10 font-medium cursor-pointer text-base lg:text-lg xl:text-xl hover:bg-primary/20 transition-all hover:text-primary';

export default function SingleQuestionNav({ id }) {
  useEffect(() => {

  }, [id]);
  return (
    <>
      <div className="flex items-center justify-center mt-5 mb-14 font-bold text-2xl sm:text-3xl lg:text-4xl lg:mb-20 xl:text-5xl xl:mb-24">
        <h1>Responses</h1>
      </div>
      <nav>
        <ul className="flex items-center justify-center">
          {tabs.map((tab, index) => (
            <li key={`${index}-${tab.name}-${tab.pathname}`}>
              <NavLink to={`/${id}/${tab.pathname}`}>
                {({ isActive }) => (
                  <span
                    className={
                      isActive
                        ? `${baseLinkStyle} border-b-4 border-b-primary text-primary`
                        : baseLinkStyle
                    }
                  >
                    {tab.name}
                  </span>
                )}
              </NavLink>
              {/* <NavLink
              to="summary"
              className="py-2 px-4 font-medium cursor-pointer text-base lg:text-lg xl:text-xl border-b-4 :border-b-primary"
            >
              {tab.name}
            </NavLink> */}
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}
